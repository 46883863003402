/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Heading, Link} from "@chakra-ui/react";
import {StackIconsGroup} from "components";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import Tour from 'videos/quick-tour-we-buy-homes-compress.mp4';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/we-buy-houses.png",
    alt: "showcase imgOne"
  })), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    size: "md"
  }, React.createElement(_components.p, null, "We Buy Houses In the Bay Area was a custom website design and development focusing on conversions and a modern look and feel. The site was built with Gatsby.js with content sources from Google Sheets, and WordPress as a Headless backend.")), "\n", React.createElement(Link, {
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.link,
    target: "_blank"
  }, props.pageContext.frontmatter.title, React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement(_components.h3, null, "Stack"), "\n", React.createElement(StackIconsGroup, {
    iconArr: ["chakraui", "gatsby", "netlify", "github", "googlesheets", "googleanalytics"]
  }), "\n", React.createElement(_components.h3, null, "Results"), "\n", React.createElement(Box, {
    mb: 6,
    as: "video",
    controls: true,
    autoplay: "autoplay",
    muted: "true",
    loop: true
  }, React.createElement("source", {
    src: Tour,
    type: "video/mp4"
  })), "\n", React.createElement(_components.p, null, "A high-performance Gatsby site with strong SEO controls and content focused around the sales funnel."), "\n", React.createElement(_components.h4, null, "Deliverables"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "SEO-optimized content management"), "\n", React.createElement(_components.li, null, "website"), "\n", React.createElement(_components.li, null, "branding"), "\n", React.createElement(_components.li, null, "design direction"), "\n", React.createElement(_components.li, null, "user experience design"), "\n", React.createElement(_components.li, null, "web design"), "\n", React.createElement(_components.li, null, "development"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
